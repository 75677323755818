<template>
  <main>
    <section>
      <b-container>
        <b-col id="page-header">
          <b-btn
            :href="`${$t('config.wiki.base')}${$t('lang') === 'fr'
              ? 'fr' : 'en'}/news/Article title`"
            size="sm"
            variant="outline-secondary float-right m-2 btn-wiki-edit"
          >
            <i class="fas fa-plus"></i>
            <span v-html="$t('news.add')"></span>
          </b-btn>
          <h2 v-html="$t('news.title')"></h2>
        </b-col>
        <b-row class="mt-4">
          <b-col lg="8">
            <article
              v-for="key in posts"
              :id="key"
              :key="key"
              class="article"
            >
              <img
                v-if="$te(`news.posts.${key}.img`) && $t(`news.posts.${key}.img`) !== ''"
                alt=""
                class="img-fluid"
                :src="$t(`news.posts.${key}.img`)"
              />
              <b-btn
                :href="`${$t('config.wiki.base')}${
                  encodeURIComponent($t(`news.posts.${key}.edit`))}/edit`"
                size="sm"
                variant="outline-secondary badge float-right mt-2 btn-wiki-edit"
              >
                <i class="fas fa-pen"></i>
                <span v-html="$t('news.edit')"></span>
              </b-btn>
              <div class="content-wrapper">
                <h3>
                  <a
                    :href="`#${key}`"
                    v-html="$t(`news.posts.${key}.title`)"
                  ></a>
                </h3>
                <p class="text-muted small">
                  <i class="fas fa-calendar"></i>
                  <time :datetime="`20${key}`">
                    {{ `20${key}` }}
                  </time>
                </p>
                <article v-html="$t(`news.posts.${key}.text`)"></article>
              </div>
            </article>
          </b-col>
          <!-- Right column -->
          <b-col lg="4">
            <div
              class="sticky-top"
              style="z-index: 999;"
            >
              <div class="bloc">
                <nav>
                  <h3>
                    <span v-html="$t(`news.latest-posts`)"></span>
                    <a
                      :href="`${$t('baseurl')}${$t('lang') === 'fr' ? 'fr' : 'en'}/news/feed.xml`"
                      style="background: none"
                      :title="this.$t('news.rss')"
                    >
                      <i class="fas fa-square-rss fa-xs fc-o6"></i>
                      <span
                        class="sr-only"
                        v-html="this.$t('news.rss')"
                      ></span>
                    </a>
                  </h3>
                  <ul>
                    <li
                      v-for="key in posts.slice(0, 5)"
                      :id="key"
                      :key="key"
                    >
                      <a
                        :href="`#${key}`"
                        v-html="$t(`news.posts.${key}.title`)"
                      ></a>
                      <time
                        class="text-muted small"
                        :datetime="`20${key}`"
                        v-text="`20${key}`"
                      ></time>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="bloc">
                <h3 v-html="$t('follow')"></h3>
                <ShareAndFollow />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
import ShareAndFollow from '../components/ShareAndFollow.vue';

export default {
  components: {
    ShareAndFollow,
  },

  metaInfo() {
    return {
      link: [
        {
          rel: 'alternate',
          type: 'application/rss+xml',
          title: this.$t('meta.title'),
          href: `${this.$t('baseurl')}${this.$t('lang') === 'fr' ? 'fr' : 'en'}/news/feed.xml`,
        },
      ],
    };
  },

  data() {
    return {
      email: '',
    };
  },

  computed: {
    posts() {
      return Object.keys(this.$t('news.posts')).slice().sort().reverse();
    },
  },

  created() {
    /* Edition mode */
    const q = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if (q.edit !== undefined) {
      document.body.classList.add('wiki-edit-enabled');
    } else {
      document.body.classList.remove('wiki-edit-enabled');
    }
  },
};
</script>

<style lang="scss">
#news {
  .article {
    background: #fff;
    margin-bottom: 40px;

    p:last-of-type > img,
    img.square {
      max-width: 420px;
      margin: 20px auto;
      display:block;
    }

    iframe {
      margin: 0 auto;
      display: block;
      max-width: 100%;
    }
  }

  .content-wrapper {
    padding: 30px;

    img {
      margin: 15px auto;
    }
  }

  article {
    margin-top: 20px;

    img {
      max-width: 100%;
    }
  }

  .btn-outline-secondary {
    &:hover {
      i::before {
        color: #fff;
      }
    }
  }

  .btn-wiki-edit {
    visibility: hidden;
  }

  &.wiki-edit-enabled {
    .btn-wiki-edit {
      visibility: visible;
    }
  }

  .nl-form {
    background: var(--j-s1);
  }
}
</style>
